<!--企业设置-->
<template>

  <Modal
      :footer="null"
      :visible="value"
      width="80%"
      dialogClass="company-dialog"
      destroy-on-close
      @cancel="closeModal">
    <ul class="company-setting" @click="closeModal">
      <li v-for="item in companyList" :key="item.companyId" @click.stop="pickCompany(item)">
        <img class="cover-img" :src="item.coverUrl" alt="">
        <div class="global-flex">
          <span class="title">{{ item.companyName }}</span>
          <Tag :class="{'master-tag': item.isMaster}" :color="item.isMaster ? '' : 'orange'" @click.stop="setMaster(item)">{{ item.isMaster ? '主企业' : '设为主企业' }}</Tag>
        </div>
        <span class="desc">{{ item.phrase }}</span>
      </li>
    </ul>
  </Modal>

</template>

<script>
  import { companyOption, masterCompany, companyPick } from '@/api/public'
  import { Tag, Modal } from 'ant-design-vue'
  export default {
    name: 'CompanySetting',
    components: {
      Tag,
      Modal
    },
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        companyList: []
      }
    },
    watch: {
      value: function() {
        if (this.value) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        companyOption().then(res => {
          this.companyList = res.data
        })
      },
      closeModal() {
        this.$emit('input', false)
      },
      setMaster(item) {
        if (item.isMaster) {
          this.$message.warning('当前企业已是主企业')
          return false
        }
        masterCompany({ masterCompId: item.companyId }).then(() => {
          this.$message.success('设置成功')
          this.init()
        })
      },
      pickCompany(item) {
        companyPick({ pickCompId: item.companyId }).then(res => {
          location.reload()
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep{
    .company-dialog{
      .ant-modal-content{
        background: transparent;
        box-shadow: none;
      }
      .ant-modal-close{
        display: none;
      }
    }
  }
  .company-setting{
    width: 1300px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li{
      cursor: pointer;
      width: 300px;
      background: #fff;
      border-radius: 4px;
      box-shadow: var(--cardShadow);
      margin: 12px;
      font-size: 14px;
      color: var(--titleColor);
      overflow: hidden;
      transition: all .3s;
      &:hover{
        box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
        transform: scale(1.1);
      }
      .cover-img{
        display: block;
        width: 100%;
        height: 148px;
        background-size: cover;
        margin: 0;
      }
      span{
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .title{
        width: calc(100% - 90px);
        font-weight: bold;
        padding: 0 12px;
        height: 40px;
        line-height: 40px;
      }
      ::v-deep{
        .ant-tag{
          display: inline-block;
          width: max-content;
          cursor: pointer;
          margin: 0 12px 0 auto;
          font-weight: 600;
        }
        .master-tag{
          background: #fa8c16;
          color: #fff;
          border-color: #fa8c16;
        }
      }
      .desc{
        padding: 0 12px;
        height: 18px;
        line-height: 18px;
        color: var(--secondaryColor);
        font-size: 12px;
        margin-bottom: 12px;
      }
    }
  }
</style>
